import { store } from '../store/storeMain'
import { transactionManager } from './transactionManager'
import { globalFunctions } from './globalFunctions'

export const transferManager = {
  transfers: [],

  getTransfer() {
    this.transfers = store.state.Transfers
  },

  addTransfer(transfer) {
    let newEntry = JSON.parse(JSON.stringify(transfer))

    this.addDialog = false

    this.getTransfer()

    newEntry.id = globalFunctions.getNextIdForObject(this.transfers)

    this.addTransferToStore(newEntry)
  },

  //getLargestIdFromItems() {
  //  this.getTransfer()

  //  let largest = 0
  //  for (let i = 0; i < this.transfers.length; i++) {
  //    if (this.transfers[i].id > largest) {
  //      largest = this.transfers[i].id
  //    }
  //  }
  //  return largest
  //},

  addTransferToStore(newEntry) {
    store.commit('addNewTransferToStore', newEntry)
  },

  createTransactionFromTransfer(transfer) {
    let transaction = this.convertTransferToTransaction(transfer)

    transactionManager.addTransaction(transaction)
  },

  convertTransferToTransaction(transfer) {
    let transaction = transactionManager.transactionObject

    //Replace Data from Income
    transaction.account = transfer.account
    transaction.transactionName = transfer.transferName
    transaction.transactionType = 'transfer'
    transaction.transactionAmount = transfer.transferAmount
    transaction.comment = transfer.comment
    transaction.category = transfer.category
    transaction.currency = transfer.currency
    transaction.transactionDate = transfer.transferDate
    transaction.transactionTime = transfer.transferTime

    //if (income.incomeType === "Recurring") {
    //  transaction.recurringTransaction = true
    //}
    //else if (income.incomeType === "OneTime") {
      transaction.recurringTransaction = false
    //}

    //transaction.repeat = income.repeat
    //transaction.cycle = income.cycle
    //transaction.period = income.period
    //transaction.numberOfCycles = income.numberOfCycles
    //transaction.goesOffFirst = income.goesOffFirst
    transaction.objectState = transfer.objectState


    return transaction
  },

  updateTransactionFromTransfer(transfer) {
    let oldTransaction = transactionManager.getTransactionByName(transfer.transferName)

    let newTransaction = this.convertTransferToTransaction(transfer)

    transactionManager.updateTransaction(oldTransaction, newTransaction)
  },

  rollbackTransactionFromTransfer(transfer) {
    transfer.objectState = 'Delete'

    this.updateTransactionFromTransfer(transfer)

  }


}
